// @flow
import * as actions from 'client/constants/actions';
import * as api from 'api';

import { apiRequestAction } from './helpers/api-request';

export { uploadMedia, deleteMedia } from './media';

export function createItem<I: AbstractItem>(params: CreateItemParams<I>): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.CREATE_ITEM,
			request: api.createItemLead,
			params,
		});

		return dispatch(requestAction);
	};
}

export function createTeacherLead<I: AbstractItem>(params: CreateItemParams<I>): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.CREATE_TEACHER_LEAD,
			request: api.createTeacherLead,
			params,
		});

		return dispatch(requestAction);
	};
}
