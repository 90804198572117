import _ from 'lodash';
import { ref, getDownloadURL, deleteObject, uploadBytesResumable } from 'firebase/storage';
import { storage } from 'common/firebase';

import { parseURL } from 'common/utils/parse-url';
import { ORIGINAL_IMAGE_IDENTIFIER, APP_CONFIG } from 'common/constants';
import * as actions from 'admin/constants/actions';

export function uploadMedia(file, options) {
	return async dispatch => {
		dispatch({ type: actions.UPLOAD_MEDIA, payload: { file, options } });
		return uploadMediaDB(file, options);
	};
}

export function deleteMedia(file) {
	return async dispatch => {
		dispatch({ type: actions.DELETE_MEDIA, payload: file });
		return deleteMediaDB(file);
	};
}

/**
 * @param file {*}
 * @param options {object}
 * @param options.isStatic {boolean}
 * @param options.extension {string}
 * @param options.filename {string}
 * @param options.name {string}
 * @returns {Promise<{name: *, url: string} | void>}
 */
const uploadMediaDB = async (file, options = {}) => {
	let originalFileName;
	let { name } = options;

	if (!name) {
		if (options.isStatic) {
			// upload static images, e.g. "share image".
			const extension = options.extension || _.last(file.name.split('.'));
			name = `static/${`${options.filename}.${extension}`}`;
		} else if (file.type.includes('video')) {
			name = `videos/${Date.now()}-${file.name}`;
		} else {
			originalFileName = file.name.replace(/\.(?!.*\.)/, `${ORIGINAL_IMAGE_IDENTIFIER}.`);
			name = `images/${Date.now()}-${originalFileName}`;
		}
	}

	const metadata = {
		contentType: file.type,
	};

	const storageRef = ref(storage, `${name}`);
	const uploadTask = uploadBytesResumable(storageRef, file, metadata);

	return new Promise((resolve, reject) => {
		uploadTask.on(
			'state_changed',
			snapshot => {
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				console.info(progress);
			},
			error => {
				console.error(error);
				reject(error);
			},
			async => {
				console.log('tuploadTask.snapshot.refest', uploadTask.snapshot.ref);
				getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
					console.log('File available at', downloadURL);
					const { pathname, search } = parseURL(downloadURL);
					const CDN_URL = `${APP_CONFIG.cdn.hostname}${pathname}${search}`;
					resolve({
						url: CDN_URL,
						name,
					});
				});
			}
		);
	});
};

const deleteMediaDB = async name => {
	const storageRef = ref(storage, `${name}`);

	return deleteObject(storageRef)
		.then(({ ...args }) => console.info('deleteMedia %O', { name, args }))
		.catch(console.error);
};
