// @flow
import { combineReducers } from 'redux';
import type { Reducer, Action } from 'redux';
import { reducer as formReducer } from 'redux-form';

import settings from './settings';

const reducer: Reducer<ClientStore, Action<{ type: string, payload: any }>> = combineReducers({
	settings,
	form: formReducer,
});

export default reducer;
