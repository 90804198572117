// @flow
export * from './auth';
export * from './backup';
export * from './crud';

export const INIT = {
	START: 'INIT.START',
	SUCCESS: 'INIT.SUCCESS',
	FAIL: 'INIT.FAIL',
};

export const SET_POP_UP = 'SET_POP_UP';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export const DELETE_MEDIA = 'DELETE_MEDIA';
